<template>
  <ion-page id="page-content">
    <ion-modal @didDismiss="showChangePwdTip = false" :is-open="showChangePwdTip" css-class="tcc-modal" :animated="false">
      <div class="model-box">
        <div class="ion-page">
          <div class="modal-content friend-modal">
            <p class="font-18 font-b main-color">Password has been reset successfully, Please login again</p>
            <div class="btn-box" style="margin-top: 30px">
              <button style="background-color: #223b86; color: #ffffff" class="btn" @click="nextEvent">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true">
      <topbar :bigtitle="true" title="Reset Password" :showBack="true"></topbar>
      <div class="pc-box big">
        <div class="pc-min-content">
          <div class="content">
            <div class="title-PC" v-if="$store.state.isPC">Reset Password</div>
            <div class="form-item">
              <label>New Password</label>
              <div class="input-box password">
                <input :type="ispassword ? 'password' : 'text'" v-model="newPassword" placeholder="New Password" @blur="checkPassword" @input="errorPassword = false" />
                <div v-if="newPassword.length > 0">
                  <i class="icon-eye" @click="ispassword = false" v-if="ispassword"></i>
                  <i class="icon-eye-blocked" @click="ispassword = true" v-else></i>
                </div>
              </div>
              <p class="tip Helveticaneue" :class="{ red: errorPassword }">8-15 characters with letters, numbers and special characters, case sensitive</p>
            </div>
            <div class="form-item">
              <label>Confirm Password</label>
              <div class="input-box password">
                <input :type="ispassword2 ? 'password' : 'text'" v-model="confirmNewPassword" placeholder="Confirm Password" @blur="checkConfirmPassword" @input="errorConfirm = false" />
                <div v-if="confirmNewPassword.length > 0">
                  <i class="icon-eye" @click="ispassword2 = false" v-if="ispassword2"></i>
                  <i class="icon-eye-blocked" @click="ispassword2 = true" v-else></i>
                </div>
              </div>
              <p class="tip red" v-if="errorConfirm">Passwords do not match</p>
              <p class="tip Helveticaneue">8-15 characters with letters, numbers and special characters, case sensitive</p>
            </div>
            <div class="pad-t-20">
              <ion-button expand="block" @click="confirmEvent" :disabled="isConfirm">Confirm</ion-button>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonModal, IonButton } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "resetPassword",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonModal, IonButton },
  data() {
    return {
      isEmail: false,
      ispassword: true,
      ispassword2: true,
      newPassword: "",
      confirmNewPassword: "",
      isConfirm: true,
      errorConfirm: false,
      errorPassword: false,
      showChangePwdTip: false,
    };
  },
  methods: {
    backEvent() {
      this.$router.push({
        path: "/login",
      });
    },
    async resetUserPasswordByPhoneEvent() {
      const objdata = this.getAuthObj();
      objdata.newPassword = this.newPassword;
      objdata.confirmNewPassword = this.confirmNewPassword;
      objdata.phone = this.$store.state.userInfo.phone;
      objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      objdata.verifyCode = this.$store.state.userInfo.smsVerifyCode;
      objdata.apiUrl = this.apiConfig.resetUserPasswordByPhone;
      const data = await this.$store.dispatch("apiPutEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      //   this.showChangePwdTip = true;
      setTimeout(() => {
        this.nextEvent();
      }, 500);
      this.successToastLT(
        "Password has been \n changed successfully, \n Please login again"
      );

      //   this.nextEvent();
    },
    async resetUserPasswordByEmailEvent() {
      const objdata = this.getAuthObj();
      objdata.newPassword = this.newPassword;
      objdata.confirmNewPassword = this.confirmNewPassword;
      objdata.email = this.$store.state.userInfo.email.trim().toLowerCase();
      objdata.verifyCode = this.$store.state.userInfo.emailVerifyCode;
      objdata.apiUrl = this.apiConfig.resetUserPasswordByEmail;
      const data = await this.$store.dispatch("apiPutEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      //   this.showChangePwdTip = true;
      //   this.nextEvent();
      setTimeout(() => {
        this.nextEvent();
      }, 500);
      this.successToastLT(
        "Password has been \n changed successfully, \n Please login again"
      );
    },
    nextEvent() {
      //   this.showChangePwdTip = false;
      if (this.$store.state.form.token != "") {
        this.$store.state.form.token = "";
        this.setStorageEvent();
      }

      this.$router.replace({
        path: "/main/landing",
      });
    },
    confirmEvent() {
      this.sendGAEvent(
        "Click",
        "Reset Password",
        "Confirm",
        this.$store.state.userInfo.registerType
      );

      if (this.$store.state.userInfo.registerType == 1) {
        this.resetUserPasswordByEmailEvent();
      } else if (this.$store.state.userInfo.registerType == 2) {
        this.resetUserPasswordByPhoneEvent();
      }
    },
    checkConfirmPassword() {
      if (this.newPassword == this.confirmNewPassword) {
        this.isConfirm = false;
        this.errorConfirm = false;
      } else {
        this.isConfirm = true;
        this.errorConfirm = true;
      }
    },
    checkPassword() {
      if (!this.$store.state.passwordPatrn.test(this.newPassword)) {
        this.errorPassword = true;
      }
      if (this.confirmNewPassword) {
        this.checkConfirmPassword();
      }
    },
  },
  ionViewWillEnter() {
    this.isEmail = false;
    this.ispassword = true;
    this.newPassword = "";
    this.confirmNewPassword = "";
    this.$store.state.exitApp = false;
    this.sendGAPageView("Reset Password");
  },
};
</script>
